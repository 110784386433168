import logo from './assets/img/logo.png';
import qr from './assets/img/qr.png';
import device from './assets/img/device.png';
import network from './assets/img/network.png';
import health from './assets/img/health.png';
import img1 from './assets/img/img1.png';
import img2 from './assets/img/img2.png';
import a from './assets/img/a.png';
import globe from './assets/img/globe.png';
import mob from './assets/img/mob.png';
import flogo from './assets/img/f-logo.png';
import ins from './assets/img/ins.png';
import lin from './assets/img/lin.png';
import fac from './assets/img/fac.png';
import pin from './assets/img/pin.png';
import goog from './assets/img/goog.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className='container'>
          <div className='row d-flex align-items-center'>
            <div className='col-md-6'>
              <a
                className="App-link"
                href="https://reactjs.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={logo} className="App-logo" alt="logo" />
              </a>
            </div>
            <div className='col-md-6'>
              <p>DEVICE INSPECTION <span>IN PROGRESS</span></p>
            </div>
          </div>
        </div>
      </header>
      <section className='sec-1'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-md-6'>
              <div className="f-left">
                <div className="row align-items-center">
                  <div className="col-md-4">
                    <ul>
                      <li>Report ID</li>
                      <li>Report Date</li>
                      <li>Client Date</li>
                      <li>Chain of Custody</li>
                    </ul>
                  </div>
                  <div className="col-md-5">
                    <ul>
                      <li>PMR09877688778676</li>
                      <li>6/29/20</li>
                      <li>PM008565</li>
                      <li>PM008565</li>
                    </ul>
                  </div>
                  <div className="col-md-3">
                    <img src={qr} className="img-fluid" alt="qr" />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className="row">
                <div className="col-md-6">
                  <ul>
                    <li><strong>TRANSACTION</strong></li>
                    <li>Date: Aug 7, 2024</li>
                    <li>Transaction ID: T0</li>
                    <li>T0JE1RJ2E</li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <ul>
                    <li><strong>STORE LOCATION</strong></li>
                    <li>QDN- Katy</li>
                    <li>mostly here</li>
                    <li>555-555-5555</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec-2">
        <div className="container">
          <div className="row pt-3">
            <div className="col-md-4 px-3">
              <div className="card-inner">
                <img src={device} className="img-fuild" alt="device" />
                <h2>DEVICE INFORMATION</h2>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="text-start">
                      <li>IMEI</li>
                      <li>Memory</li>
                      <li>Color</li>
                      <li>Model</li>
                      <li>Serial #</li>
                      <li>Model #</li>
                      <li>Manufacturer</li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="text-start">
                      <li>8765439876</li>
                      <li>56GB</li>
                      <li>Space Gray</li>
                      <li>iPhone X</li>
                      <li>F6543NP8776MC</li>
                      <li>MG9N6LL/A</li>
                      <li>Apple</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 px-3">
              <div className="card-inner">
                <img src={network} className="img-fuild" alt="network" />
                <h2>NETWORK COMPATABILITY</h2>
                <ul className="text-center">
                  <li>AT&T</li>
                  <li>Sprint</li>
                  <li>Cricket</li>
                  <li>Verizon</li>
                  <li>T-Mobile</li>
                  <li>Metro PCS</li>
                  <li>Boost Mobile</li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 px-3">
              <div className="card-inner">
                <img src={health} className="img-fuild" alt="health" />
                <h2>DEVICE BATTERY HEALTH</h2>
                <ul className="text-start">
                  <li><i className="fa-regular fa-circle-check"></i> Passed: Tested Acceptable</li>
                  <li><i className="fa-solid fa-circle"></i> Caution: Tested Warning</li>
                  <li><i className="fa-solid fa-ban"></i> Failed: Tested Unacceptable</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec-3">
        <div className="container">
          <div className="row pt-3">
            <div className="col-md-6">
              <div className="img-box">
                <img src={img1} className="img-fluid" alt="img1" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="img-box">
                <img src={img2} className="img-fluid" alt="img2" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec-4">
        <div className="container">
          <div className="row py-5">
            <div className="col-md-4 px-3">
              <div className="card-inner">
                <h2>SOFTWARE & DIAGNOSTICS</h2>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="text-start">
                      <li>OS</li>
                      <li>OS Version</li>
                      <li>Firmware</li>
                      <li>Rooted</li>
                      <li>Report UUID</li>
                      <li>Device ID</li>
                      <li>Device Date</li>
                      <li>Invoice</li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="text-end">
                      <li>iOS</li>
                      <li>10.3.2</li>
                      <li>5.62.00</li>
                      <li>No</li>
                      <li>U76543</li>
                      <li>E651765F7J97/A</li>
                      <li>6/29/20</li>
                      <li>Lot3</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 px-3">
              <div className="card-inner">
                <h2>DEVICE DATA & SECURITY</h2>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="text-start">
                      <li>Erasure Type</li>
                      <li>Erasure Confirmation</li>
                      <li>Erasure Date</li>
                      <li>Blacklisted</li>
                      <li>Lost or Stolen</li>
                      <li>FMIP</li>
                      <li>ESN</li>
                      <li>Initial Carrier</li>
                      <li>SIMlock</li>
                      <li>Locked Carrier</li>
                      <li>MDM Lock</li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="text-end">
                      <li>Datawipe</li>
                      <li>G4S11P2A6C6E8</li>
                      <li>6/29/20</li>
                      <li>Passed</li>
                      <li>No</li>
                      <li>No</li>
                      <li>876543123</li>
                      <li>Verizon</li>
                      <li>No</li>
                      <li>Unlocked</li>
                      <li>No</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 px-3">
              <div className="card-inner">
                <h2>DEVICE INFORMATION</h2>
                <ul>
                  <li>Device: iPhone</li>
                  <li>Version: 10.3.2</li>
                  <li>Memory: 56GB</li>
                  <li>Storage: 32GB</li>
                  <li>Battery Health: 80%</li>
                  <li>Network: Unlocked</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
